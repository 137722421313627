import React, { useState } from 'react'
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import image from "../../assets/images/courses/ntt2-01.jpg";
import { Modal, Button } from 'react-bootstrap'
import HubspotContactForm from './hubspot/HubspotContactForm';

const CoursesPage = () => {
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <Layout pageTitle="KWEC | Course Details">
      <Navbar />
      <PageHeader title="Course Details" />
      <section className="course-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="course-details__content">
              <div className="course-details__top">
                <div className="course-details__top-left">
                  <h2 className="course-details__title">Nursery Teacher Training</h2>
                </div>
                <div className="course-details__top-right">
                  <a className="course-one__category">
                    For aspiring teachers
                  </a>
                </div>
              </div>
              <div className="course-one__image">
                <img src={image} alt=""  width={100}/>
              </div>
              <div className="tab-content course-details__tab-content ">
                <div
                  className="tab-pane show active  animated fadeInUp"
                  role="tabpanel"
                  id="overview"
                >
                  <p className="course-details__tab-text"> A career in the field of early childhood education is absolutely promising and fulfilling, especially when young minds have the potential to surprise you every day! Play a vital role in the development of future go-getters. Become a certified Early Childhood Educator and start your career as a qualified teacher.  </p>
                  <br/>
                  <h4>
                  Enroll in our job-oriented and internationally-accepted Teachers’ Training Diploma course, Today!
                  </h4>
                  <ul>
                                    <li>Affiliated with Government registered and ISO certified organization</li>
                                    <li>Experienced staff conducting theory and practical sessions</li>
                                    <li>Workshops on effective use of teaching aids, art and craft activities, puppet making, among other activity-based teaching techniques</li>
                                    <li>Weekend batches with flexible timings</li>
                                    <li>Great value at an affordable price</li>
                                    <li>Job placement assistance in schools post completion of the course</li>
                                    <li>
                                        Option to get enhanced certifications on:-
                                        <ul>
                                            <li>Jolly Phonics Teachers’ Training Course</li>
                                            <li>Brain Gym for Little Ones</li>
                                            <li>Pre-KG Nursery Teachers’ Training for children with special needs</li>
                                        </ul>
                                    </li>
                                </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 ">
            <div className="course-details__price">
              <p className="course-details__price-text">
                Talk to our executive and enroll now!{' '}
              </p>
              <Button
                onClick={handleShow}
                className="thm-btn course-details__price-btn"
                variant="default"
              >
                Enquire Now
              </Button>
            </div>
          </div>
          <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Enquiry Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HubspotContactForm/>
              </Modal.Body>
            </Modal>
        </div>
      </div>
    </section>
      <Footer />
    </Layout>
  );
};

export default CoursesPage;
